export default defineAppConfig({
    layout: {
        showTitle: true,
        navigation: {
            enabled: true,
            logo: {
                component: 'div'
            },
            header: {
                /*component: 'WorkspaceDropdown'*/
            },
            items: [
                {
                    name: 'Merchants',
                    to: '/merchants'
                },
                {
                    name: 'Providers',
                    to: '/providers'
                },
                {
                    name: 'Tariffs',
                    to: '/tariffs'
                },
                {
                    name: "Transactions",
                    to: '/transactions'
                },
                {
                    name: "Users",
                    to: '/users'
                },
                {
                    name: "Refs",
                    to: '/refs'
                },
                {
                    name: "Fin",
                    to: '/finance'
                }
            ]
        },
        toolbar: {
            enabled: true,
            component: 'AdminToolbar',
        },
    }
});
